import React, { Component } from "react";
import EnhancedTable from './table2.js';
import loader from './loader.gif';
import logo from './logo2.png';
import Form from './form.js'
import TableAll from './tableall.js'
import TableToAll from './toall.js'


import {
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBFooter,
  MDBAnimation,
} from 'mdbreact';


var CountryTo = ""       
var CountryFrom = ""       
var ProductCat = ""


class Stating extends Component{
    constructor(){
        super()
        this.state = {
          from: "",
          to: "",
          category: "",
          isLoading: false,
          bodyLoaded: false,
          items: [],
          description: true,
          readMoreId: [],
          posts: [],
        }}
    
          
       
            handleFormChange = (e)=>{
          this.setState({
            api: e.target.value,
          })
          console.log(this.state)
        }
        /*componentDidMount(){
          console.log('starting body posts')
          fetch('https://pakn.eu:8000/posts/')
          .then(response => response.json())
          .then(response => {
              this.setState({
                posts: response
              });})
            

            };  
          */  
        handleFormSubmit = () => {
          this.setState({isLoading: true})
          fetch('https://pakn.eu:8000/comparer/api/parcellist/')
          .then(res => res.json()).then(console.log(CountryTo))
          .then(
            (result) => {
              this.setState({
                category: ProductCat,
                from: CountryFrom,
                to: CountryTo, 
                items: result,
                isLoading: false,

              });})
          .then(console.log("submitted"))
        }
        
        render(){
          
          function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
          }
                   
         
          const {items} = this.state;
          

          return(
            
          <body>
          <div> 
          <a name="top"></a>

<header style={{height:250, backgroundColor:"#0058EF"}}></header>
<nav class="navbar navbar-expand-lg navbar-light bg-dark fixed-top transparent py-0" id="mainNav" style ={{backgroundColor:"#F00", opacity: "0.6", filter:"(opacity=50)"}}>
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="https://pakn.eu/" style={{color:"#FFF"}}>Pakn.eu</a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto my-2 my-lg-0">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="https://pakn.eu/#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="https://pakn.eu/#contact">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="https://pakn.eu/blog/">Blog</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
<MDBAnimation type="zoomIn" duration="500ms">

        <div className='mt-3 mb-5'>
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md='10'
                className='mx-auto float-none white z-depth-1 py-2 px-2'
              >
                <MDBCardBody className='text-center'>
                  <h2 className='h2-responsive mb-4'>
                    <strong className='font-weight-bold'>
                      Compare parcel prices in Europe
                    </strong>
                  </h2>
                  <MDBRow />
                  <form>
                  <label >From  </label>

           <select onChange={e => {CountryFrom = e.target.value}}>
               <option value=""></option>
               <option value="Austria">Austria</option>
              <option value="Belgium">Belgium</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Croatia">Croatia</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czechia">Czechia</option>
              <option value="Denmark">Denmark</option>
              <option value="Estonia">Estonia</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="Germany">Germany</option>
              <option value="Greece">Greece</option>
              <option value="Hungary">Hungary</option>
              <option value="Ireland">Ireland</option>
              <option value="Iceland">Iceland</option>
              <option value="Italy">Italy</option>
              <option value="Latvia">Latvia</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Malta">Malta</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Norway">Norway</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Romania">Romania</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Spain">Spain</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="all">All</option>


            </select>
            <label>To  </label>

           <select onChange={e => {CountryTo = e.target.value}}>
           <option value=""></option>
               <option value="austria">Austria</option>
              <option value="belgium">Belgium</option>
              <option value="bulgaria">Bulgaria</option>
              <option value="croatia">Croatia</option>
              <option value="cyprus">Cyprus</option>
              <option value="czechia">Czechia</option>
              <option value="denmark">Denmark</option>
              <option value="estonia">Estonia</option>
              <option value="finland">Finland</option>
              <option value="france">France</option>
              <option value="germany">Germany</option>
              <option value="greece">Greece</option>
              <option value="hungary">Hungary</option>
              <option value="ireland">Ireland</option>
              <option value="iceland">Iceland</option>
              <option value="italy">Italy</option>
              <option value="latvia">Latvia</option>
              <option value="lithuania">Lithuania</option>
              <option value="luxembourg">Luxembourg</option>
              <option value="malta">Malta</option>
              <option value="netherlands">Netherlands</option>
              <option value="norway">Norway</option>
              <option value="poland">Poland</option>
              <option value="portugal">Portugal</option>
              <option value="romania">Romania</option>
              <option value="slovakia">Slovakia</option>
              <option value="slovenia">Slovenia</option>
              <option value="spain">Spain</option>
              <option value="sweden">Sweden</option>
              <option value="switzerland">Switzerland</option>
              <option value="all">All</option>

            </select>
            <label>Type</label>
            <select onChange={e => {ProductCat = e.target.value}}>
            <option value=""></option>
              <option value="500">a 500 g letter (all types)</option>
              <option value="1">a 1 kg parcel (all types)</option>
              <option value="2">a 2 kg parcel (all types)</option>
              <option value="5 kg">a 5 kg (or bigger) parcel (all types)</option>
              <option value="all">All</option>
            </select>
        <input value="Search" type ="button" onClick={this.handleFormSubmit}/>
        </form>

                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
          {(console.log(this.state.isLoading))} 

        </div>
        <section class="ftco-section">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
{this.state.to === 'all' && this.state.from === 'all'? "Choose different search parameters, all countries to all countries is too vast.": <div style={{textAlign:"center"}}>{this.state.isLoading === true && <p>Datatable is loading  <img src={loader} alt="loading" width="32px" /></p>}</div>}
          { items.length < 1 || this.state.to === 'all' && this.state.from === 'all'?
            <div>
       <section id="about">
                    <div style={{textAlign:"center"}}>                    <img src={logo} alt="something" width="350px" />
                    <h2> What is it? </h2>
                    <h5>Using pakn.eu you can compare parcel prices between all the European Union countries.</h5> 
                    </div>
                    <p>
                      <b>The Data:</b> It's the official prices which companies report to regulatory bodies at the beginning of the year. 
                      If the data is incorrect or missing please let us know</p> 
                      <p>
                      <b>Listed prices</b> are the official prices from January 2022 that don&rsquo;t include VAT (as this might vary)                       
                      There are main categories of parcels listed (0.5 , 1, 2, 5 kgs)  (heavier parcels up to 20-32 kgs seem to be similarly priced.) 
                      More accurate data for heavier parcels and dimensions will be added in the future.
                    </p>
                    <p>
                      <b>The goal of comparison</b> is to see which company offers the cheapest service and to know what companies actually exist. 
                      The most common carriers are rarely the cheapest.  
                      Depending on the carrier it might be the dimensions that influence the price more than the weight. 
                    </p>
                    <p><b>There is more to it</b> always double-check the price on the company website, and check their reviews. 
                    There might also be intermediaries that re-sell the service at lower rates but usually the cheapest listing from the table WILL be the cheapest option</p>              
</section>
        <section class="page-section" id="contact">
          <Form/>
</section>
</div>
:
          
          
					<div class="table-wrap">
{(console.log(this.state.isLoading))} 
<h2 class="h5 mb-4 text-center">From {capitalizeFirstLetter(this.state.from)} to {capitalizeFirstLetter(this.state.to)}</h2> 
<p style={{color:"red"}}> <b> <i>Click on the property to sort. Sorts by price automatically - <u>prices dont include VAT </u></i></b> </p>



{(() => {
  if (this.state.from==='all') {
    return (
      <TableAll rows={items} from={this.state.from} to={this.state.to} category={this.state.category}/> 

    )
  } else if (this.state.to==="all") {
    return (
      <TableToAll rows={items} from={this.state.from} to={this.state.to} category={this.state.category}/> 
    )
  } else {
    return (
<EnhancedTable rows={items} from={this.state.from} to={this.state.to} category={this.state.category}/> 
    )
  }
})()}



</div>
        }
        
        
       </div>
       </div>
       </div>
       </section>
  </MDBAnimation>
        <MDBFooter color='blue' className='font-small pt-4 mt-4' id="footer">
          
          <div className='footer-copyright text-center py-3'>
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Pakn.eu   </MDBContainer>
          </div>
        </MDBFooter>
        </div>
    </body>
    )
    
        }
    
    }
  export default Stating;
  
  /*
  var i;
var divs = document.getElementsByClassName('cell');
  for(i=0;i<divs.length;i++) {
    var checker1 = divs[i].innerHTML
    var checker = checker1.length
    if(checker > 200) {
    divs[i].innerHTML = divs[i].innerHTML.substring(0,200) + "......"; }}
    */