import React from 'react';



let counter = 0

class Form extends React.Component{

    constructor(){
        super()
        this.state = {
          name: "",
          email: "",
          message: "",
        }}

          
        handleFormChange = (e)=>{
            e.preventDefault()
            this.setState({
              name: e.target.value,
            })
            console.log(this.state)
            fetch('https://pakn.eu:8000/contact/', {
      method: "POST",
      headers: { 
      'Content-Type': 'application/json',

      },
      body: JSON.stringify(this.state),
     
    }).then((response) => (response.json())
      ).then(counter=+1).then(this.setState({
        name:"", email:"", message:""
      })).then((response)=> {
    if (response.status === 201) {
      this.resetForm()
    } else {
    }
  })
}
          
       
          

        render(){
           
      return(
          
        <div>
        <h2>Send us a message</h2>
        <form id="contact-form" onSubmit={this.handleFormChange}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" value={this.state.name}  onChange={e => this.setState({name: e.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="name">Email</label>
            <input type="text" className="form-control"  value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
          </div>
          <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={e => this.setState({message: e.target.value})} />
          </div>
          <button type="submit" className="btn btn-primary" >Submit</button>

          
        </form>
        { counter > 0 &&
        <p style={{backgroundColor:"green"}}>submmitted</p>
        }


<p>{this.state.Name}</p>
</div>
);
}

}
export default Form;