import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  CardBody,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

function ActionButton(props) {
  return (

<UncontrolledDropdown>
<DropdownToggle
  color="primary"
  size="sm"
  className="m-2">
  {props.name}
</DropdownToggle>
<DropdownMenu
  right
  className="dropdown-menu-xl overflow-hidden p-0">
 <Card className="card-box mb-5" style={{width:"400px"}} >
            <div className="card-header bg-light">
              <div className="card-header--title">
                <b>Product details</b>
              </div>
            </div>
            <CardBody>
              <PerfectScrollbar className="scroll-area-xs shadow-overflow">
          <p>{props.description}</p>
    
          </PerfectScrollbar>
            </CardBody>
          </Card>
</DropdownMenu>
</UncontrolledDropdown>
  );
}
export default ActionButton;