
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import ActionButton from './ActionButton';
import './index.css';

let rows = 0;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}





export default function TableToAll(props) {
  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
   
    return (

<TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minHeight:500,
      minWidth: 750,
      maxWidth: 1600,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'f',
      top: 20,
      width: 1,
    },
  }));
  rows = props.rows.filter(row => (props.from ==="all"? true :props.from === row.country) && (props.category ==="all"? true : row.productcategory.includes(props.category)))
  let placeholder = props.to
  const headCells = [
    { id: capitalizeFirstLetter('country'), numeric: false, disablePadding: true, label: 'From' },
    { id: 'company', numeric: false, disablePadding: true, label: 'Company' },
    { id: '', numeric: false, disablePadding: true, label: 'Description' },
/*    { id: props.to, numeric: true, disablePadding: false, label: 'Price' },*/
    { id: 'productcategory', numeric: false, disablePadding: false, label: 'Category' },
    { id: props.belgium, numeric: true, disablePadding: true, label: 'BE' },
    { id: props.bulgaria, numeric: true, disablePadding: true, label: 'BG' },
    { id: props.croatia, numeric: true, disablePadding: true, label: 'CR' },
    { id: props.cyprus, numeric: true, disablePadding: true, label: 'CY' },
    { id: props.czechia, numeric: true, disablePadding: true, label: 'CZ' },
    { id: props.denmark, numeric: true, disablePadding: true, label: 'DK' },
    { id: props.estonia, numeric: true, disablePadding: true, label: 'EST' },
    { id: props.finland, numeric: true, disablePadding: true, label: 'FIN' },
    { id: props.france, numeric: true, disablePadding: true, label: 'FR' },
    { id: props.germany, numeric: true, disablePadding: true, label: 'GER' },
    { id: props.greece, numeric: true, disablePadding: true, label: 'GR' },
    { id: props.hungary, numeric: true, disablePadding: true, label: 'HU' },
    { id: props.iceland, numeric: true, disablePadding: true, label: 'ICE' },
    { id: props.ireland, numeric: true, disablePadding: true, label: 'IRE' },
    { id: props.italy, numeric: true, disablePadding: true, label: 'IT' },
    { id: props.latvia, numeric: true, disablePadding: true, label: 'LV' },
    { id: props.lithuania, numeric: true, disablePadding: true, label: 'LI' },
    { id: props.luxembourg, numeric: true, disablePadding: true, label: 'LUX' },
    { id: props.malta, numeric: true, disablePadding: true, label: 'MT' },
    { id: props.netherlands, numeric: true, disablePadding: true, label: 'NT' },
    { id: props.norway, numeric: true, disablePadding: true, label: 'NOR' },
    { id: props.poland, numeric: true, disablePadding: true, label: 'PL' },
    { id: props.portugal, numeric: true, disablePadding: true, label: 'PT' },
    { id: props.romania, numeric: true, disablePadding: true, label: 'RO' },
    { id: props.slovakia, numeric: true, disablePadding: true, label: 'SK' },
    { id: props.slovenia, numeric: true, disablePadding: true, label: 'SV' },
    { id: props.spain, numeric: true, disablePadding: true, label: 'ES' },
    { id: props.sweden, numeric: true, disablePadding: true, label: 'SWE' },
    { id: props.switzerland, numeric: true, disablePadding: true, label: 'SWX' },
  
  ];
  
  console.log(rows)
  console.log(rows.country)
  console.log("the number of props.rows is" + props.rows.length)
  console.log("the number of rows is " + rows.length)

  console.log(placeholder)
  console.log(headCells)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState([props.to]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  function dummy(topic){
    let vary = "http://www.google.com/s2/favicons?domain=" + topic
    return vary;
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
/*
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
/*
const filtering = (props) => props.from === row.country && 
(if (props.category ==='all'){
true
}
else if(props.category.includes('500'))
{
  row.productcategory === "a 500 g (domestic and intra Union) track and trace letter" && "a 500 g (domestic and intra Union) registered letter" && "a 500 g (domestic and intra Union) standard letter"
}
(props.category ==="all"? true :props.category === row.productcategory) && row.[props.to] != null)
*/

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const cell = {
    border:'1px solid #eeeeee', 
    backgroundColor:"#fffaaa",
    padding: 6,
    fontFamily: "Arial",
    height:50,
    width:40,
    '&:hover':{
      backgroundColor:"#ffffff",
  }
};
{ let trunky =(text)=>{
  try{  
  var checker1 = text.innerHTML
  var checker = checker1.length
  if (checker > 50) {
  return text.innerHTML = text.substring(0,50) + "..."; }
  }
  catch (e) {
    
    return console.log('Error')
    
}}  }

  return (
    
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              id="tablehead"
            />
            <TableBody id="tablebody">
              
              {stableSort(props.rows.filter(row => (props.from ==="all"? true :props.from === row.country) && (props.category ==="all"? true : row.productcategory.includes(props.category))&& row.[props.to]!= 0 ), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  /*const labelId = `enhanced-table-checkbox-${index}`;*/
                  return (
                    <TableRow 
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={{height:50}}
                    >
                      <TableCell padding="checkbox" align="right" id="companylogo">
                      <div className="img"> <img width="40px" height="40px" src={dummy(row.website)} alt="logo"/></div>
                      </TableCell>
                      <TableCell align="right" id='country'  ><b>{row.country}</b></TableCell>

                      <TableCell align="left" id="companylink"><a href={row.website}>{row.company}</a></TableCell>
                      <TableCell align="left"style={{border:'1px solid #eeeeee'}}><ActionButton name={"Service info"}description={row.productname}/></TableCell>
                      {/*<TableCell align="right" style ={backgroundColor:"#fffaaa", border:'1px solid #eeeeee'}}> <b>{row.[props.to]}</b></TableCell>*/}
                      <TableCell style={{width:50}} align="right">{row.productcategory}</TableCell>
                      <TableCell className={ 'cells' }><b>{row.belgium}</b></TableCell>
                      <TableCell className={ 'cells' }> <b>{row.bulgaria ? row.bulgaria: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.croatia? row.croatia : " - "}</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.cyprus ? row.cyprus: " - "  }</b></TableCell>
                      <TableCell align="left" className={ 'cells' }> <b>{row.czechia? row.czechia: " - "  }</b></TableCell>
                      <TableCell align="left" className={ 'cells' }> <b>{row.denmark ? row.denmark: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.estonia ? row.estonia: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.finland ? row.finland: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.france ? row.france: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.germany ? row.germany: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.greece ? row.greece: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.hungary ? row.hungary: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.ireland? row.ireland: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.iceland? row.iceland: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.italy? row.italy: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.latvia ? row.latvia: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.lithuania ? row.lithuania: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.luxembourg ? row.luxembourg: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.malta ? row.malta: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.netherlands ? row.netherlands: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.norway? row.norway: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.poland? row.poland: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.portugal? row.portugal: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.romania? row.romania: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.slovakia? row.slovakia: " - "  }</b></TableCell>
                      <TableCell align="right" className={ 'cells' }> <b>{row.slovenia? row.slovenia: " - "  }</b></TableCell>
                      <TableCell className={ 'cells' }> <b>{row.spain? row.spain: " - " }</b></TableCell>
                      <TableCell className={ 'cells' }> <b>{row.sweden? row.sweden: " - "  }</b></TableCell>
                      <TableCell  className={ 'cells' }> <b>{row.switzerland? row.switzerland: " - " }</b></TableCell>
                  
                    </TableRow>
                  );
                })}
              
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}